// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-jobs-computer-vision-engineer-js": () => import("./../src/pages/jobs/computer-vision-engineer.js" /* webpackChunkName: "component---src-pages-jobs-computer-vision-engineer-js" */),
  "component---src-pages-jobs-data-js": () => import("./../src/pages/jobs/data.js" /* webpackChunkName: "component---src-pages-jobs-data-js" */),
  "component---src-pages-jobs-frontend-js": () => import("./../src/pages/jobs/frontend.js" /* webpackChunkName: "component---src-pages-jobs-frontend-js" */),
  "component---src-pages-jobs-java-js": () => import("./../src/pages/jobs/java.js" /* webpackChunkName: "component---src-pages-jobs-java-js" */),
  "component---src-pages-jobs-mobile-js": () => import("./../src/pages/jobs/mobile.js" /* webpackChunkName: "component---src-pages-jobs-mobile-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-js": () => import("./../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-products-cars-js": () => import("./../src/pages/products/cars.js" /* webpackChunkName: "component---src-pages-products-cars-js" */),
  "component---src-pages-products-foundation-js": () => import("./../src/pages/products/foundation.js" /* webpackChunkName: "component---src-pages-products-foundation-js" */),
  "component---src-pages-products-tacx-js": () => import("./../src/pages/products/tacx.js" /* webpackChunkName: "component---src-pages-products-tacx-js" */),
  "component---src-pages-products-time-js": () => import("./../src/pages/products/time.js" /* webpackChunkName: "component---src-pages-products-time-js" */),
  "component---src-pages-products-vital-js": () => import("./../src/pages/products/vital.js" /* webpackChunkName: "component---src-pages-products-vital-js" */),
  "component---src-pages-projects-xbike-js": () => import("./../src/pages/projects/xbike.js" /* webpackChunkName: "component---src-pages-projects-xbike-js" */)
}

